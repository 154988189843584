.list-of-samples-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: visible;
    border-radius: 10px;
}

.list-of-samples-border-container {
    width: 96%;
    height: 97.5%;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: visible;
    border-radius: 10px;
    flex-direction: column;
    position: relative;
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #28272C;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 250, 205, 0.40);
  }

.no-samples-loaded {
    color: white;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    align-items: center;
}

nav {
    width: 50%;
    padding: 0% 10% 0% 25%
}

ul {
    padding: 0% 25% 0% 25%;
}

.pagination-indicator-container {
    display: flex;
    align-items: center;
    width: 70%;
    position: fixed;
    bottom: 2.5vh;
}

.pagination-switch-container {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 2vh;
    right: 20px;
    color: white;
}

.number-of-sample-switch-pagination {
    color: #feecc6;
    padding: 0% 10% 0% 25%;
    width: 50%
}


.arrow-not-selected-element {
    color: white
}

.arrow-selected-element {
    color: #ff8585
}

/* MOBILE */

@media screen and (max-width: 950px) {
    .right-side-container {
        width: 100%;
        height: 60%;
    }

    .list-of-samples-container {
        flex: 8;
        width: 100%;
        min-height: 0;
    }

    .list-of-samples-border-container {
        width: 100%;
    }
}
