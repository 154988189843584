.footer-container {
    height: 10%;
    background-color: #28272C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 12px;
    flex-direction: column;
}

.credits-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    height: 61%;
    flex-direction: column;
    gap: 0.4em;
}

.sony-footer {
    height: 39%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 300;
    font-size: 11px;
    box-sizing: border-box;
    padding: 0rem 22rem;
}

a {
    color: white;
    text-decoration: none;
}




@media screen and (max-width: 950px) {
    .credits-container {
      flex-direction: column;
      font-size: 10px;
    }

    .sony-footer {
        padding: 0rem 1rem;
    }
  }