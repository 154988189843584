.sample-infos-pop-up-main-container {
    background-color: #3B3650;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 12em;
    height: 10em;
}