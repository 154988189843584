.drumkit-mobile-main-container {
    display: flex;
    width: 100%;
    flex: 5;
    align-items: center;
    justify-content: center;
}

.drumkit-mobile-border-container {
    background-color: #28272c;
    width: 100%;
    height: 97.5%;
    border-radius: 10px;

}

@media screen and (min-width: 951px) {
    .drumkit-mobile-main-container {
        display: none;
    }
}