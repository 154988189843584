.helper-text-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36em;
    height: auto;
    border-radius: 10px;
    background-color: #3B3650;
    position: absolute;
    top: 89%;
    right: 2em;
    z-index: 6;
    color: white;
    box-sizing: border-box;
    padding: 2em;
    text-align: start;
}