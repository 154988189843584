.library-select-drop-down-main-container {
    display: flex;
    align-items: center;
    width: 16em;
    height: auto;
    border-radius: 10px;
    background-color: #28272c;
    position: absolute;
    top: 88%;
    right: 1em;
    z-index: 6;
    color: white;
    box-sizing: border-box;
    padding: 0.5em;
    text-align: start;
    flex-direction: column;
    max-height: 450%;
    overflow-y: scroll;
}