.cosine-similarity-wrapper {
    display: flex;
    width: 70%;
    height: 8%;
    position: relative;
    align-items: center;
    justify-content: left;
    background-color: #dfe6e9;
    color: white;
    border-radius: 15px;
}

.cosine-similarity-filler {
    background-color: #5ea094;
    border-radius: 15px;
    height: 100%;
    width: 50%;
}
