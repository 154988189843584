.rounded-button-container {
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    background-color: #3F4043;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 42%);
    cursor: pointer;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: ease-out 150ms;

}

.rounded-button-container:hover {
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

.rounded-button-container-disabled {
    cursor: not-allowed;
    background-color: #28272c;
}

.rounded-button-container-disabled:hover {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 42%);
    background-color: #28272c;
}