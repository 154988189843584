.library-select-drop-down-item-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4em;
}

.library-select-drop-down-item-border-container {
    width: 100%;
    height: 89%;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    padding: 1em 1.2em;
    font-size: 14px;
    box-sizing: border-box;
    font-weight: 200;
    overflow: hidden;
    background-color: #28272c;
}

.library-select-drop-down-icon-and-name-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}