.table-foot-container {
    width: 100%;
    height: 11%;
    background-color: #28272C;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1em;
    box-sizing: border-box;
    z-index: 2;
}

.footer-arrows-container {
    /* width: 15%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.footer-results-container {
    /* width: 30%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    white-space: pre;
    flex: 1;
}

.footer-switch-container {
    /* width: 15%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    white-space: pre;
    cursor: pointer;
    flex: 1;
}

@media screen and (max-width: 950px) {
    .footer-arrows-container {
        display: none;
    }

    .table-foot-container {
        display: none;
    }
}