.filters-dialog-main-container {
    position: absolute;
    width: 36%;
    height: 42%;
    border-radius: 10px;
    background-color: #3B3650;
    left: 2%;
    top: 10%;
    z-index: 6;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.8em;
}

.filters-dialog-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 950px) {
    .filters-dialog-main-container {
        width: 80%;
    }
}