.drum-pad {
    flex-direction: column;
    display: flex;
    width: 7rem;
    height: 7rem;
    font-size: 14px;
    overflow: hidden;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #1A191E;
    border-bottom: 4px solid;
    border-right: 4px solid;
    border-top: 0.1px solid;
    border-left: 0.1px solid;
    box-sizing: border-box;
    margin: 0px 0.5em;
    padding: 0.5em;
    border-radius: 10px;
    position: relative;
}

.drum-pad:hover {
    border: 2px solid
}

.drum-pad-mobile {
    flex-direction: column;
    display: flex;
    width: 8em;
    height: 82%;
    font-size: 14px;
    overflow: hidden;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #1A191E;
    border-bottom: 4px solid;
    border-right: 4px solid;
    border-top: 0.1px solid;
    border-left: 0.1px solid;
    box-sizing: border-box;
    margin: 0px 0.5em;
    padding: 0.5em;
    border-radius: 10px;
    position: relative;
}

.drum-pad-key-board-container {
    width: auto;
    height: auto;
    display: flex;
    font-weight: 600;
    box-sizing: border-box;
    position: absolute;
    bottom: 4%;
    right: 6%;
}

.drum-pad-drum-type-container {
    width: 70%;
    height: auto;
    display: flex;
    font-weight: 600;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.drum-pad-drum-icon-container {
    display: flex;
    height: 100%;
    flex: 1;
}

.drum-pad-drum-tag-container {
    display: flex;
    flex: 1;
    height: 100%;
}

.drum-pad-plus-icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 950px) {
    .drum-pad {
        width: 80px;
        height: 80px;
    }
    .drum-pad-mobile {
        width: 80px;
        height: 80px;
    }
}