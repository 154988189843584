.upload-main-page-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-main-page-border-main-container {
    width: 98%;
    height: 96%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28272c;
    border-radius: 10px;
}

.upload-main-page-dashed-border-main-container {
    width: 98%;
    height: 96%;
    display: flex;
    align-items: center;
    border: dashed 2px white;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    gap: 2em;
    padding: 1em;
    box-sizing: border-box;
}

.loading-gif-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 20%;
    flex-direction: column;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
}

.select-library-button-container {
    width: auto;
    position: relative;
}