.big-rounded-button-main-container {
    width: 100%;
    height: 60%;
    background-color: #3B3650;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 1px 3px 2px rgb(0 0 0 / 42%);
    margin: 0px 0px 0px 1em;
    cursor: pointer;
    transition: 150ms ease-out;
    overflow: hidden;
    text-decoration: underline;
    box-sizing: border-box;
}

.big-rounded-button-main-container:hover {
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

@media screen and (max-width: 950px) {
    .big-rounded-button-main-container {
        font-size: 12px !important;
        margin: 0;
    }

}