.upload-custom-library-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4em;
}

.upload-custom-library-border-container {
    width: 100%;
    height: 89%;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    padding: 1em 1.4em;
    font-size: 14px;
    box-sizing: border-box;
    font-weight: 200;
    background-color: #3F4043;
}

.upload-custom-library-logo-and-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em
}