.drum-kit-container {
    height: 58%;
    width: 100%;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.drum-kit-border-container {
    background-color: #28272c;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drum-kit-select-container {
    width: 100%;
    height: 13%;
}

.drum-kit-pads-container {
    width: 100%;
    height: 70%;
}

.drum-kit-actions-container {
    width: 100%;
    height: 17%;
    display: flex;
}

.drum-kit-actions-left-side-container {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 1em;
}

/* Where the volume is set */
.durm-kit-actions-left-side-first-line-container {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
}

/* Where the keyboard language is set */
.drum-kit-actions-left-side-second-line-container {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
}

.drum-kit-actions-right-side-container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0.2em;
}

.drum-kit-first-line {
    display: flex;
    height: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.drum-kit-second-line {
    display: flex;
    height: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
}

.keyboard-container {
    color:  #feecc6 !important;
    font-size: 20px;
    cursor: pointer;
}

.keyboard-text {
    font-size: 11px;
}

.keyboard-icon {
    padding: 0px 8px !important;
    color:  #feecc6 !important;
    font-size: 20px;
    cursor: pointer;
}

.drumkit-volume-container {
    display: flex;
    align-items: center;
    width: 70%;
}

.drum-kit-loading-autopopulate-container {
    width: 14%;
    height: 25%;
}

/* MOBILE */

@media screen and (max-width: 950px) {
    .drum-kit-container {
        display: none;
    }

    .drum-kit-select-container {
        display: none;
    }

    .drum-kit-pads-container {
        height: 100%;
        padding: 10px;
        min-height: 70px;
    }

    .drumkit-volume-container {
        display: none;
    }

    .drum-kit-button {
        display: none !important;
    }

    .drumkit-controls-container {
        display: none;
    }
}