.uploadandplay-main-container {
    display: flex;
    align-items: center;
    height: 90%;
    /* width: 45%; */
    flex: 2;
    background: #1A191E;
    border-radius: 15px;
    z-index: 1;
    padding: 0 0.8em;
    gap: 0.3em;
}
.upload-and-play-left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    gap: 1em;
}

.upload-and-play-logo-container {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.upload-and-play-logo-border-container {
    display: flex;
    height: 72%;
    width: 100%;
    background: #28272C;
    border-radius: 7px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}


.upload-and-play-upload-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
}

.upload-and-play-song-name-container {
    display: flex;
    color: white;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 60%;
}

.upload-and-play-song-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    /* align-items: center;
    justify-content: center; */
}

.upload-and-play-audio-player-main-container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.border-component {
    border-left: 1px solid white;
    position: relative;
    height: 35%;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drum-kit-button {
    position: absolute !important;
    bottom: 10vh !important;
}

.rounded-button-container {
    border-radius: 50%;
    width: 2.2em;
    height: 2.2em;
    background-color: #3F4043;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 42%);
    cursor: pointer;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: ease-out 150ms;

}

.rounded-button-container:hover {
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

/* MOBILE */

@media screen and (max-width: 950px) {
    .uploadandplay-main-container {
        width: 100%;
    }

    .upload-and-play-left-container {
        width: 40%;
    }

    .upload-and-play-audio-player-main-container {
        width: 60%;
    }
}