.right-side-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 0.5em 0px;
}

@media screen and (max-width: 950px) {
    .right-side-container {
        width: 100%;
        height: 70%;
        padding: 0;
        padding-bottom: 0.5rem;
    }
}

.drumkit-mobile-container {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: center
}