.rounded-text-info-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 90%;
    padding: 1.5em 2em;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    border-radius: 44px;
    color: white;
    background-color: #3F4043;
    font-size: 15px;
    font-weight: 200;
}