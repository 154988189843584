.wave-form-container-main-container {
    display: flex;
    align-items: center;
    height: 42%;
    width: 100%;
    justify-content: center;
}

.wave-form-container-border-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #28272c;
    border-radius: 10px;
    justify-content: center;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.wave-form-border-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #1A191E;
    width: 98%;
    height: 94.5%;
}

@media screen and (max-width: 950px) {
    .wave-form-container-main-container {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: center;
    }
    .wave-form-container-border-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #28272c;
        border-radius: 10px;
        justify-content: center;
        box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    }
}