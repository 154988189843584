.drumkit-mobile-toolbar-main-container {
    display: flex;
    width: 100%;
    background-color: #28272c;
    flex: 1;
    color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;
    justify-content: center;
    min-height: 70px;
}

@media screen and (min-width: 951px) {
    .drumkit-mobile-toolbar-main-container {
        display: none;
    }
}