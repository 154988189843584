.filter-button-container {
    width: 60%;
    height: 1.8em;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    place-self: center;
    cursor: pointer;
    transition: ease-out 150ms;
    padding: 0.5em;
    gap: 0.6em;
}

.filter-button-container:hover {
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

.filter-button-icon-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.filter-button-name-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
}