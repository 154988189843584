
.table-body-container {
    width: 100%;
    height: 78%;
    overflow-y: scroll;
    overflow-x: visible;
    background-color: #28272C;
    cursor: pointer;
    position: relative;
    will-change: transform;
}

.table-cell-border-container {
    width: 100%;
    height: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
}

.table-cell-container {
    width: 100%;
    background-color: #28272C;
    height: 95%;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}


@media screen and (max-width: 950px) {
    .table-body-container {
        height: 88%;
    }
}