.table-head-border-container {
    width: 100%;
    height: 11%;
    background-color: #28272C;
    z-index: 2;
}

.table-head-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #3F4043;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1em;
    position: relative;
}

.list-of-samples-header-left-part-container {
    width: 33%;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1em;
}

.list-of-samples-header-right-part-container {
    display: flex;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .table-head-border-container {
        height: 12%;
        min-height: 69px;
    }
}
