.left-container-main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.5em 0;
}

@media screen and (max-width: 950px) {
    .left-container-main-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 30%;
        box-sizing: border-box;
        padding: 0;
        padding-top: 0.5rem;
    }
}