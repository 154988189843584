.sample-name-and-buttons-container {
    display: flex;
    width: 60%;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.drum-icon-container {
    display: flex;
    width: 14%;
    height: 100%;
    position: relative;
    align-items: center;
    padding: 0px 1em;
    position: relative;
}

.sample-similarity-container {
    display: flex;
    width: 20%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
}

.play-and-download-icon-container {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sample-name-container {
    width: 60%;
}

.left-border {
    border-left: 1px solid white;
    position: absolute;
    bottom: 30%;
    top: 30%;
    left: 0;
}

.icon-and-tag {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    font-size: 16px;
    height: 100%;
}

.popover {
    border-radius: 50% !important;
}

@media screen and (max-width: 950px) {
    .play-and-download-icon-container {
        width: 60%;
    }
}