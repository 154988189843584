.sample-name {
    color:white;
    cursor: pointer;
    font-weight: 200;
    font-size: 16px;
    text-transform: capitalize;
    width: 60%;
    display: flex;
    overflow: hidden;
}

.sample-name-selected {
    color:white;
    cursor: pointer;
    font-weight: 200;
    font-size: 16px;
    text-transform: capitalize;
    width: 60%;
    display: flex;
    overflow: hidden;
}