.header {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-border-container {
    width: 99%;
    height: 70%;
    display: flex;
    align-items: center;
    background-color: #28272C;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

.logo-and-sample-match-name-container {
    display: flex;
    align-items: center;
    /* width: 20%; */
    flex: 0.6;
    height: 100%;
    margin-right: 30px
}

.header-left-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    /* width: 35%; */
    flex: 1;
    height: 100%;
    z-index: 3;
    box-sizing: border-box;
    background-image: url("../../assets/header-pattern.svg");
    background-size: contain;
    padding-right: 2em;
}

.sample-match-logo {
    height: 100%;
}

.sony-header {
    display: flex;
    height: 30%;
    width: 100%;
    background-color: #000000;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 8rem;
    box-sizing: border-box;
}

.sony-logo {
    margin-left: 6.5%;
    /*display: none;*/
}

.helper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 33%;
    width: 10%;
    height: 42%;
    background-color: #4B3B58FF;
    border-radius: 10px;
    color: white;
    z-index: 1;
    gap: 7%;
    padding: 0 0.7%;
}

.helper-text {
    color: white;
    font-size: 14px;
    text-decoration: underline;
}

.header-buttons-container {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media screen and (max-width: 950px) {
    .header-left-container {
        width: 15%;
        justify-content: center;
        flex: 0;
        padding-right: 0;
        padding: 0.5rem;
    }

    .header-border-container {
        justify-content: center;
    }
}