.sample-type-button-main-container {
    width: 100%;
    height: 34%;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    place-self: center;
    cursor: pointer;
    transition: ease-out 150ms;
    gap: 0.6em;
    padding: 0.5em;
    text-transform: uppercase;

}

.sample-type-button-drum-icon-container {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.sample-type-button-sample-type-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
}