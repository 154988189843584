.App {
  text-align: center;
  background-color: #1A191E;
  width: 100vw;
  height: 100vh;
}

.middle-container {
  display: flex;
  height: 75%;
  background-image: url("./assets/design-block.svg");
  padding: 0 4em
}

@media screen and (max-width: 950px) {
  .middle-container {
    display: flex;
    height: 75%;
    flex-direction: column;
    align-items: center;
    padding: 0 1em;
  }
}

@media screen and (max-width: 1366px) and (orientation: landscape)  {
  @supports (-webkit-touch-callout: none) {
    .App {
      height: 92.5vh;
      overflow: hidden;
    }
  }
}
